import { graphql, PageProps } from 'gatsby';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Layout from '../components/Layout/Layout.component';
import { PageContext } from '../contexts/PageContext';
import useDynamicComponent from '../hooks/useDynamicComponent.hook';
import getMetaData from '../utils/getMetaData';
import { getPageStyle } from '../utils/getPageStyle';
import { MembershipContext } from '../contexts/MembershipContext';
import { StoryblokNode } from '../interfaces/StoryblokNode.interface';
import {
  Membership,
  MembershipDetails,
  Promotion,
} from '../components/Memberships/memberships.interface';
import { Gym } from '../interfaces/GymData';
import TagManager from 'react-gtm-module';
import { orderMemberships } from '../utils/orderMemberships';

export interface StoryblokEntry {
  content: string;
  full_slug: string;
  id: string;
  name: string;
  uuid: string;
}

interface StoryblokEntryData {
  storyblokEntry: StoryblokEntry;
  membershipData: {
    memberships: Membership[];
    concessionMemberships: Membership[];
    membershipDetails: MembershipDetails;
  };
  gym: Gym;
}

const StoryblokEntryTemplate: React.FC<PageProps<any, StoryblokEntryData>> = ({
  data,
  location,
  pageContext,
}) => {
  const blocks = useDynamicComponent(data.storyblokEntry, location);
  const seo = getMetaData(data?.storyblokEntry.content);
  const pageStyle = getPageStyle(data?.storyblokEntry.content);
  const pageUuid = data.storyblokEntry.uuid;

  useLayoutEffect(() => {
    window.dataLayer = window.dataLayer || [];
    const referrer = window.dataLayer?.find(
      (item) => item.event === 'page_view'
    )
      ? localStorage.getItem('prevURL')
      : document.referrer;
    window.dataLayer.push({
      event: 'page_view',
      location: window.location.href,
      referrer: referrer,
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('prevURL', window.location.href);
  }, []);

  return (
    <PageContext.Provider
      value={{
        uuid: pageUuid,
        isHeadingFlush: pageStyle === 'flush',
        gym: pageContext?.gym,
      }}
    >
      <MembershipContext.Provider
        value={{
          memberships: orderMemberships(
            pageContext?.membershipData?.memberships
          ),
          concessionMemberships: orderMemberships(
            pageContext?.membershipData?.concessionMemberships
          ),
          membershipDetails: pageContext?.membershipData?.membershipDetails,
        }}
      >
        <Layout metaData={seo}>{blocks}</Layout>
      </MembershipContext.Provider>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      uuid
      id
      name
      full_slug
      content
    }
  }
`;

export default StoryblokEntryTemplate;
